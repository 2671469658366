// =============================================================================
// Definition
// =============================================================================
class Trigger {

	// Constructor
	// -------------------------------------------------------------------------
	constructor({
		el = "",
		clickCb = () => null,
	}) {
		this.el = el;
		this.clickCb = clickCb;

		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	initEvents() {
		this.el.addEventListener("click", () => this.handleTrigger());
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleTrigger(e) {
		if (e) {
			e.preventDefault();
		}

		if (this.clickCb) {
			this.clickCb();
		}
	}
}

export default Trigger;
