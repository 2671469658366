// =============================================================================
// Imports */
// =============================================================================
import modules from "./modules";

// =============================================================================
// Init */
// =============================================================================

/**
 * Info
 * -----
 * Main init function that initialise all the components if they exist in the
 * DOM of the loaded page.
 */

const init = () => {
	modules();
};

// =============================================================================
// Document ready */
// =============================================================================

/**
 * Info
 * -----
 * Eventlistener that is only triggerd if the state of the document changes. If
 * the readystate of the document is equal to "complete" (so when DOM and
 * styles are loaded), then the components JavaScript is initialised. This is
 * done to make calculations concerning the bounding box correct.
 *
 * Options
 * -----
 * - interactive: Only DOM is loaded
 * - complete: DOM, styles and subsets like images are loaded
 */

document.addEventListener("readystatechange", () => {
	if (document.readyState === "complete") {
		init();
	}
});
