// =============================================================================
// Imports
// =============================================================================
import { Toggle } from "./../../extensions";

// =============================================================================
// Definition
// =============================================================================
class Header extends Toggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		super({
			el,
			toggleClass: "is-open",
		});
	}

	// Handlers
	// -------------------------------------------------------------------------
	open() {
		super.enable();
	}

	close() {
		super.disable();
	}
}

export default Header;
