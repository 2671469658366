// =============================================================================
// Imports
// =============================================================================
import { Toggle } from "./../../extensions";
import Hamburger from "./Hamburger";
import Nav from "./Nav";
import Header from "./Header";

// =============================================================================
// Definition
// =============================================================================
class MobileNav extends Toggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el, {
		bodyClass = "has-overlay-open",
	}) {
		super({
			el,
			toggleClass: "is-open",
		});

		this.bodyClass = bodyClass;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.hamburger = new Hamburger(this.el.querySelector(".c-hamburger"), {
			openCb: () => this.open(),
			closeCb: () => this.close(),
		});

		this.nav = new Nav(this.el.querySelector(".c-navigation"));
		this.header = new Header(this.el.querySelector(".c-mobile-header"));
	}

	// Hanlders
	// -------------------------------------------------------------------------
	open() {
		super.enable();

		this.nav.open();
		this.header.open();

		document.querySelector("body").classList.add(this.bodyClass);
	}

	close() {
		super.disable();

		this.nav.close();
		this.header.close();

		document.querySelector("body").classList.remove(this.bodyClass);
	}
}

export default MobileNav;
