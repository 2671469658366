// =============================================================================
// Definition
// =============================================================================
class Video {

	// Constructor
	// -------------------------------------------------------------------------
	constructor({ el = "" }) {
		this.el = el;
	}

	// Handlers
	// -------------------------------------------------------------------------
	play() {
		this.el.play();
	}

	pause() {
		this.el.pause();
	}
}

export default Video;
