// =============================================================================
// Imports
// =============================================================================
import { Toggle } from "./../../extensions";
import Trigger from "./Trigger";
import Close from "./Close";
import Video from "./Video";

// =============================================================================
// Definition
// =============================================================================
class Popup extends Toggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el, {
		bodyClass = "has-overlay-open",
	}) {
		super({
			el,
			toggleClass: "is-open",
		});

		this.bodyClass = bodyClass;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.global = this.getGlobalPopup();
		this.cookieName = "GLOBAL_POPUP_COOKIE";
		this.cookieTime = this.getCookieTime();
		this.hasCookie = this.getCookie(this.cookieName);

		if (this.global === "true" && !this.hasCookie) {
			this.handleOpen();
		}

		// Get the popup
		this.name = this.el.getAttribute("data-popup-name");
		this.video = this.el.querySelector(".c-video")
			? new Video({
				el: this.el.querySelector(".c-video"),
			})
			: null;

		// initalise triggers
		this.triggers = Array.from(document.querySelectorAll(`[data-popup-trigger="${this.name}"]`))
			.map((item) => new Trigger({
				el: item,
				clickCb: () => this.handleOpen(),
			}));

		// initalise close buttons
		this.closeButtons = Array.from(document.querySelectorAll(`[data-popup-close="${this.name}"]`))
			.map((item) => new Close({
				el: item,
				clickCb: () => this.handleClose(),
			}));
	}

	// Getters
	// -------------------------------------------------------------------------
	getGlobalPopup() {
		return this.el.getAttribute("data-global");
	}

	getCookieTime() {
		return this.el.getAttribute("data-time");
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleOpen() {
		super.enable();

		document.querySelector("body").classList.add(this.bodyClass);

		if (this.video) {
			this.video.play();
		}
	}

	handleClose() {
		super.disable();

		document.querySelector("body").classList.remove(this.bodyClass);

		if (this.video) {
			this.video.pause();
		}

		if (this.global && this.cookieTime) {
			this.setCookie(this.cookieName, true, 1);
		}
	}

	handleToggle() {
		super.toggle();
	}

	setCookie(name, value, time) {
		const date = new Date();

		date.setTime(date.getTime() + time * 3600 * 1000);
		document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
	}

	getCookie(cname) {
		const name = `${cname}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(";");

		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];

			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}

			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}

		return "";
	}
}

export default Popup;
