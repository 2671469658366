/* eslint-disable */
// =============================================================================
// Definition
// =============================================================================
class Stacks {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		initEvents();
	}
}

var ScrollDir;
var ScrollOffset;
var cards = [];

var stackOffset = 1.5;
var firstStackTop = 5;
var firstStackHeight = 0;

function initEvents(){
	window.addEventListener('load', function () {
		// prep stacks
		initStacks();

		// prep cards for animation
		initCards();
	});

	window.addEventListener('scroll', function () {
		// detect scroll directions
		if(this.oldScroll > this.scrollY){
		  ScrollDir = "up";
		  ScrollOffset = this.oldScroll - this.scrollY;
		}else{
		  ScrollDir = "down";
		  ScrollOffset = this.scrollY - this.oldScroll;
		}
		this.oldScroll = this.scrollY;

		// scale panels
		cards.forEach(function(el) {
			scaleCard(el);
		});
	});

	window.addEventListener('resize', function () {
		// re-position stacks
		initStacks();
	});

}

function initStacks(){
	var stacks = document.querySelectorAll(".js-stack");
	stacks.forEach(function(el, i) {
		positionStack(el, i);
	});
}

// position the stacks.
function positionStack(el, i){
	// desktop only
	if(window.innerWidth > 500){
		var top = 0;
		var height = 0;
		var z = i + 1;

		// first stack is special
		if(i == 0){
			// fixed top
			top = firstStackTop;

			// calculate the height and convert it to VH
			firstStackHeight = el.querySelector(".js-stack__intro").getBoundingClientRect().height;
			firstStackHeight = (firstStackHeight /  window.innerHeight * 100) + firstStackTop;
		}else{
			top = firstStackHeight + ( i * stackOffset );
		}

		// height is relative to the top offset
		height = 90 - top;

		el.style.position = "sticky";
		el.style.top = top + "vh";
		el.style.height = height + "vh";
		el.style.zIndex = z;
		el.style.marginBottom = 0;
	}else{
		// reset styling
		el.style.position = "relative";
		el.style.top = 0;
		el.style.height = "auto";
		el.style.zIndex = 1;
		el.style.marginBottom = "30px";
	}
}


function initCards(){
	var getCards = document.querySelectorAll(".js-stack__card");
	getCards.forEach(function(el) {
		// apply transform origin to handle the scaling
		el.style.transformOrigin = "center top";

		// create array & save element
		var card = [];
		card.push(el);
		card.push(0);
		card.push("fixedOffset");
		cards.push(card);
	});
}

function scaleCard(el){
	var card = el[0];
	var lastY = el[1];
	var fixedOffset = el[2];
	var bounds = card.getBoundingClientRect();
	var scaleDiff = 0.001 * (ScrollOffset/10);

	// check if card is stationary
	if(bounds.top == lastY){

			// save the fixed offsetTop for later
			el[2] = bounds.top;

			// get current scale
			var scale = bounds.width / card.offsetWidth;

			// change scale depending on scroll direction
			if(ScrollDir == "down"){
				scale = scale - scaleDiff;
			}else{
				scale = scale + scaleDiff;
			}

			if(scale > 1){
				scale = 1;
			}

			// set new scale & opacity
			requestAnimationFrame(function(){
				updateCard(card, scale);
			});

	}else{
		if(bounds.top > lastY){
			// make sure we reset the scale & opacity when the object is no longer fixed
			if(bounds.top > fixedOffset){
				updateCard(card, 1);
				el[2] = "";
			}
		}
	}

	// update last Y
	el[1] = bounds.top;
}

// Update Card properties
function updateCard(card, scale){
	card.style.transform = "scale(" + scale + ")";
}

export default Stacks;
