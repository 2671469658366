// =============================================================================
// Definition
// =============================================================================
class ResizeManager {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el, {
		toggleClass = "u-resize-animation-stopper",
	}) {
		this.el = el;
		this.class = toggleClass;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		window.addEventListener("resize", () => this.handleWindowResize());
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleWindowResize() {
		let resizeTimer = 0;

		// Add the class to stop animations
		this.el.classList.add(this.class);

		// Remove the class again after a certain amount of time
		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(() => {
			this.el.classList.remove(this.class);
		}, 400);
	}
}

export default ResizeManager;
