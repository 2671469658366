// =============================================================================
// Definition
// =============================================================================
class Collapsable {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		const toggle = this.el.querySelector(".js-toggle-collapsable");

		toggle.addEventListener("click", () => {
			this.el.classList.toggle("is-open");
		});
	}
}

export default Collapsable;
