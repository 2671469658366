// =============================================================================
// Imports
// =============================================================================
import ResizeObserver from "resize-observer-polyfill";
import Swiper, { Navigation, Pagination } from "swiper";

class StoryCarousel {
	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	events() {
		this.resizeObserver = new ResizeObserver(() => this.resize());
		this.resizeObserver.observe(this.el);
	}

	init() {
		Swiper.use([Navigation, Pagination]);
		this.nav = this.el.querySelector(".c-story-carousel__nav");
		this.setSwiper();
		this.togglePagination();
	}

	setSwiper() {
		this.swiper = new Swiper(this.el, this.getSwiperSettings());
	}

	togglePagination() {
		if (this.el.classList.contains("is-disabled")) {
			this.el.classList.remove("is-disabled");
		}

		if (this.swiper.pagination.bullets.length === 1) {
			this.el.classList.add("is-disabled");
		}
	}

	getSwiperSettings() {
		return {
			slidesPerView: 1,
			spaceBetween: 42,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			pagination: {
				el: ".c-story-carousel__swiper-pagination",
				type: "bullets",
				clickable: true,
			},
		};
	}

	resize() {
		this.swiper.destroy();
		this.setSwiper();
	}
}

export default StoryCarousel;
