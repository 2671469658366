// =============================================================================
// Imports
// =============================================================================
// Utilities
import { dom } from "../utils";

// Modules
import Carousel from "../modules/Carousel";
import Popup from "../modules/Popup";
import MobileNav from "../modules/MobileNav";
import ResizeManager from "../modules/ResizeManager";
import Collapsable from "../modules/Collapsable";
import Stacks from "../modules/Stacks";
import Form from "../modules/Form";
import LanguageToggle from "../modules/LanguageToggle";
import StoryCarousel from "../modules/StoryCarousel";

// =============================================================================
// Definition
// =============================================================================

/**
 * Info
 * -----
 * Initialise and export all initialised components to be used on the different
 * component pages and in the final build.
 *
 * Usage: dom.init({ selector: "[.js-[class]]", Module: [name] }),
 */

export default () => [
	dom.init({
		selector: "body",
		Module: ResizeManager,
	}),
	dom.init({
		selector: ".js-collapsable",
		Module: Collapsable,
	}),
	dom.init({
		selector: ".js-stacks",
		Module: Stacks,
	}),
	dom.init({
		selector: ".js-carousel",
		Module: Carousel,
	}),
	dom.init({
		selector: ".js-story-carousel",
		Module: StoryCarousel,
	}),
	dom.init({
		selector: ".js-form",
		Module: Form,
	}),
	dom.init({
		selector: ".js-language-toggle",
		Module: LanguageToggle,
	}),
	dom.init({
		selector: ".js-popup",
		Module: Popup,
		params: {
			bodyClass: "has-overlay-open",
		},
	}),
	dom.init({
		selector: ".js-mobile-nav",
		Module: MobileNav,
		params: {
			bodyClass: "has-overlay-open",
		},
	}),
];
