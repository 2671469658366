// =============================================================================
// Definition
// =============================================================================
const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

class LanguageToggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.toggleClass = "is-open";
		this.isOpen = false;

		this.triggerEl = this.el.querySelector("[data-trigger]");
		this.id = this.triggerEl.getAttribute("data-trigger");
		this.contentEl = this.el.querySelector(`[data-content="${this.id}"]`);
		this.focusableEls = this.contentEl.querySelectorAll(focusableSelector);

		this.initEvents();
	}

	initEvents() {
		this.triggerEl.addEventListener("click", () => this.toggleState());
	}

	handleClickOutside(e) {
		if (!this.el.contains(e.target)) {
			this.handleClose();
		}
	}

	destroy() {
		this.triggerEl.removeEventListener("click", () => this.toggleState());
	}

	toggleState() {
		if (!this.el) {
			return;
		}

		if (this.el.classList.contains(this.toggleClass)) {
			this.handleClose();
		} else {
			this.handleOpen();
		}
	}

	handleOpen() {
		if (this.el) {
			this.el.classList.add(this.toggleClass);
			this.isOpen = true;
			this.triggerEl.setAttribute("aria-expanded", this.isOpen);
			document.addEventListener("click", (e) => this.handleClickOutside(e));
		}
	}

	handleClose() {
		if (this.el) {
			this.el.classList.remove(this.toggleClass);
			this.isOpen = false;
			this.triggerEl.setAttribute("aria-expanded", this.isOpen);
			document.removeEventListener("click", (e) => this.handleClickOutside(e));
		}
	}
}

export default LanguageToggle;
