// =============================================================================
// Imports
// =============================================================================
import Glide from "@glidejs/glide";

// =============================================================================
// Definition
// =============================================================================
class Carousel {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.glide = new Glide(this.el, {
			type: "carousel",
			perView: 1,
		});

		this.glide.mount();
	}
}

export default Carousel;
