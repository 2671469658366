// =============================================================================
// Imports
// =============================================================================
import Select from "./Select";

// =============================================================================
// Definition
// =============================================================================
class Form {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.select = new Select(this.el.querySelector(".js-form__select select"), {
			changeCb: () => this.setToEmail(),
		});
		this.toEmailInput = this.el.querySelector(".js-form__emails");
		this.mails = this.toEmailInput
			.dataset.mails
			.slice(1, -1)
			.split(", ");
	}

	// Handlers
	// -------------------------------------------------------------------------
	setToEmail() {
		// Get the index number of the selected subject
		const selectedIndex = this.select.getSelectedIndex();

		// Minus 1, because the first item is disabled and has no value
		const toEmail = this.mails[selectedIndex - 1];

		// Set the value of the hidden input field
		this.toEmailInput.value = toEmail;
	}
}

export default Form;
