// =============================================================================
// Definition
// =============================================================================
class Select {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el, {
		changeCb = () => null,
	}) {
		this.el = el;
		this.changeCb = changeCb;

		this.initEvents();
	}

	initEvents() {
		this.el.addEventListener("change", () => this.handleChangeSelect());
	}

	// Helpers
	// -------------------------------------------------------------------------
	getSelectedIndex() {
		return this.el.selectedIndex;
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleChangeSelect() {
		this.changeCb();
	}
}

export default Select;
