// =============================================================================
// Imports
// =============================================================================
import { Toggle } from "./../../extensions";

// =============================================================================
// Definition
// =============================================================================
class Hamburger extends Toggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el, {
		openCb = () => null,
		closeCb = () => null,
	}) {
		super({
			el,
			toggleClass: "is-active",
		});

		this.openCb = openCb;
		this.closeCb = closeCb;

		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	initEvents() {
		this.el.addEventListener("click", () => this.handleClickHamburger());
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleClickHamburger() {
		super.toggle();

		if (this.isToggled) {
			this.openCb();
		} else {
			this.closeCb();
		}
	}
}

export default Hamburger;
